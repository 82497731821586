import { Injectable } from '@angular/core';
import { jsPDF, jsPDFOptions } from 'jspdf';
import autoTable, { CellHookData, Color, UserOptions } from 'jspdf-autotable';
import { SpinnerService } from '../../../shared/services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class ViewReportExportPdfService {
  constructor(private readonly spinnerService: SpinnerService) {}

  getDefaultPDFOptions(customPDFOptions = {}): jsPDFOptions {
    const defaultPDFOptions: jsPDFOptions = {
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    };
    return { ...defaultPDFOptions, ...customPDFOptions };
  }

  setFontStyle(doc: jsPDF): void {
    doc.setFont('helvetica', 'normal', 'bold');
    doc.setFontSize(11);
  }

  setDocumentHeader(doc: jsPDF, header): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(header.title.toUpperCase(), 10, 15, { align: 'left' });
    doc.text(`DATE: (${header.date.toUpperCase()})`, pageWidth - 20, 15, { align: 'right' });
    doc.text(header.tableCaption, pageWidth / 2, 25, { align: 'center' });
  }

  getAutoTableUserOptions(): UserOptions {
    const endYCoord = 27;
    const tableLineColor: Color = [154, 144, 100];
    const tableCellLineColor: Color = [229, 235, 235];
    const customTableStyleOptions: UserOptions = {
      html: '#dxfw-wdr-view-report-table', // table id
      theme: 'grid',
      startY: endYCoord,
      tableLineWidth: 0.1,
      //tableLineColor: tableLineColor,
      styles: {
        halign: 'center',
        textColor: [51, 51, 51],
        lineColor: tableLineColor,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [199, 193, 169],
        lineColor: tableLineColor,
        lineWidth: 0.01,
        minCellHeight: 10,
        fontSize: 9,
      },
      bodyStyles: {
        minCellHeight: 10, // Increase row height
        fontSize: 8,
        lineColor: tableCellLineColor,
      },
      // styles: { overflow: 'linebreak' },
      // columnStyles: { 0: { cellWidth: tableWidth / 10 }, 1: { cellWidth: tableWidth / 4 }, 2: { cellWidth: tableWidth / 4 }, 3: { cellWidth: tableWidth / 4 } },
      columnStyles: {
        0: { halign: 'left' },
      },
      pageBreak: 'avoid',
      margin: { left: 10 },
      didParseCell: (data: CellHookData) => {
        const columnIndex = data.column.index;
        data.cell.styles.halign = columnIndex < 2 ? 'left' : 'right'; // Align the first two cells to the left
        if (columnIndex === 1) {
          data.cell.styles.cellWidth = 55;
        }
        if (data.section === 'body') {
          const rowIndex = data.row.index;
          // const cellValue = parseInt(data.cell.text[0], 10);
          // const rows = data.table.body;
          // const lastRowIndex = rows.length - 1;

          data.cell.styles.valign = 'middle';

          if (rowIndex % 2 === 0) {
            data.cell.styles.fillColor = [240, 240, 240]; // set color for even rows
          }
          // else if (rowIndex === lastRowIndex) {
          //   data.cell.styles.fillColor = [235, 231, 178]; // set color for the last row
          // }
          // if (cellValue < 0) {
          //   data.cell.styles.textColor = [255, 0, 0]; // Red color for negative values
          // }
        }
      },
      didDrawCell: (data: CellHookData) => {
        // data.cell.height = 50;
        // data.cell.width = 100;
        data.cell.styles.valign = 'middle';
      },
    };
    return customTableStyleOptions;
  }

  printTables(doc: jsPDF): void {
    const tables = document.querySelectorAll('table'); // select tables
    tables.forEach((_table: HTMLTableElement, _index: number) => {
      const autoTableUserOptions = this.getAutoTableUserOptions();
      autoTable(doc, autoTableUserOptions);
    });
  }

  savePdf(doc: jsPDF, fileName: string): void {
    doc.save(`${fileName}.pdf`);
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
  }

  async exportToPdf(reportData): Promise<void> {
    this.spinnerService.displaySpinner();
    const pdfOptions = this.getDefaultPDFOptions();
    const fileName = `${reportData.title}_${reportData.date}_${Date.now()}`;
    const doc = new jsPDF(pdfOptions);
    this.setFontStyle(doc);
    this.setDocumentHeader(doc, reportData);
    // const tables = document.querySelectorAll('table'); // select tables
    this.printTables(doc);
    this.savePdf(doc, fileName);
    this.spinnerService.hideSpinner();
  }
}
