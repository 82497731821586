import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  constructor(private readonly userProfileService: UserProfileService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = { Authorization: '', 'Dx-User-Agent': '' };

    if (this.userProfileService.getProfile().token) {
      headers['Authorization'] = `Bearer ${this.userProfileService.getProfile().token}`;
    }
    headers['Dx-User-Agent'] = 'DXFW';

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
