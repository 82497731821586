import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio'; // Import MatRadioModule from '@angular/material/radio'
import { MatFormFieldModule } from '@angular/material/form-field'; // Import MatFormFieldModule from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'; // Import MatInputModule from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'; // Import MatButtonModule from '@angular/material/button'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'; // Import MatDialogModule from '@angular/material/dialog'
import { MatTabsModule } from '@angular/material/tabs'; // Import MatTabsModule from '@angular/material/tabs'
import { MatSidenavModule } from '@angular/material/sidenav'; // Import MatSidenavModule from '@angular/material/sidenav'
import { MatTableModule } from '@angular/material/table'; // Import MatTableModule from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon'; // Import MatIconModule from '@angular/material/icon'
import { MatSortModule } from '@angular/material/sort'; // Import MatSortModule from '@angular/material/sort'
import { MatDatepickerModule } from '@angular/material/datepicker'; // Import MatDatepickerModule from '@angular/material/datepicker'
import { MatTooltipModule } from '@angular/material/tooltip'; // Import MatTooltipModule from '@angular/material/tooltip'
import { MatDividerModule } from '@angular/material/divider'; // Import MatDividerModule from '@angular/material/divider'
import { MatListModule } from '@angular/material/list'; // Import MatListModule from '@angular/material/list'
import { MatCardModule } from '@angular/material/card'; // Import MatCardModule from '@angular/material/card'
import { MatStepperModule } from '@angular/material/stepper'; // Import MatStepperModule from '@angular/material/stepper'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginatorIntlService } from '../services/custom-paginator-intl.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatSidenavModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatSortModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDividerModule,
    MatListModule,
    MatStepperModule,
    DragDropModule,
    MatSnackBarModule,
    MatPaginatorModule,
  ],
  exports: [
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatSidenavModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatSortModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDividerModule,
    MatListModule,
    MatStepperModule,
    DragDropModule,
    MatSnackBarModule,
    MatPaginatorModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntlService },
  ],
})
export class MaterialModule {}
