import { Component, Input } from '@angular/core';
import { IStepperHeader } from '../../../../shared/interface/build-reports-stepper.interface';

@Component({
  selector: 'app-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss']
})
export class StepperHeaderComponent {
  @Input() stepperDetails: IStepperHeader;
}
