import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() public showSpinner = false;
  public size = 'large';
  public toyotaUrl = '';

  ngOnInit(): void {
    this.toyotaUrl = '../../../../assets/loaders/gifs/red-large-spinner-w-logo.gif';
  }
}
