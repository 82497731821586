<div class="dxfw-wdr-build-report-container">
  <mat-card *ngFor="let reportTemplate of reportTemplates">
    <mat-card-content>
      <div class="dxfw-wdr-build-report-cont__title">{{ reportTemplate.template_name }}</div>
      <div class="dxfw-wdr-build-report-cont__desc">{{ reportTemplate.template_description }}</div>
    </mat-card-content>
    <mat-card-footer>
      <div class="dxfw-wdr-build-report-cont__actions">
        <button
          class="primary-button action small-button"
          [disabled]="!allowedTemplates.includes(reportTemplate.template_name)"
          (click)="onclickReportTemplate(reportTemplate)">
          <mat-icon>add</mat-icon>
          Create New Report
        </button>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
