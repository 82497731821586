import { Injectable } from '@angular/core';
import { IFindCollection, IMultiSelectOptions } from '../interface/common.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DEFAULT_BUTTON_LABELS, WDR_CONSTANT } from '../../constants/wdr.constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsUtilityService {
  constructor(private readonly snackBar: MatSnackBar, private readonly dialog: MatDialog) {}

  /**
   * Displays a success message using a snackbar.
   * @param message - The message to be displayed.
   * @param action - The action text to be displayed.
   */
  showSuccessMessage(message: string, action = 'X') {
    return this.snackBar.open(message, action, {
      duration: WDR_CONSTANT.snackBarDuration,
      panelClass: ['snackbar-success', 'dxfw-wrc-snackbar-container'],
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }

  /**
   * Displays an error message using a snackbar.
   * @param message - The error message to display.
   * @param action - The action text to display in the snackbar.
   */
  showErrorMessage(message: string, action = 'X') {
    return this.snackBar.open(message, action, {
      duration: WDR_CONSTANT.snackBarDuration,
      panelClass: ['snackbar-error', 'dxfw-wrc-snackbar-container'],
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }

  /**
   * To find an item from the collections, based on keyRef
   * To get only a specific key for the filtered collection use resultKeyRef
   * @param collectionParam {IFindCollection}
   */
  findCollections(collectionParam: IFindCollection) {
    const findCollection = collectionParam.collections?.find((collection) => {
      return collection[collectionParam.keyRef].toString() === collectionParam.compareValue.toString();
    });
    if (!findCollection) {
      return null;
    }
    return collectionParam.resultKeyRef ? findCollection?.[collectionParam.resultKeyRef] : findCollection;
  }

  deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  compareObject(object1: any, object2: any): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  objectIncludesInCollection(collections: any[], obj: Object): boolean {
    return collections
      .map((collection) => {
        return JSON.stringify(collection);
      })
      .includes(JSON.stringify(obj), 0);
  }

  openDialog(component: any, customDialogOptions?: any) {
    const defaultDialogOptions = {
      panelClass: 'dxfw-wdr-dialog-panel',
      disableClose: true,
      autoFocus: false,
      minWidth: 490,
      maxHeight: '90vh',
      data: '',
    };
    const dialogOptions = { ...defaultDialogOptions, ...customDialogOptions };
    return this.dialog.open(component, dialogOptions);
  }

  convertToMultiSelectOptions(options: any[], idField: string, valueField: string): IMultiSelectOptions[] {
    if (!options || options.length === 0) {
      return [];
    }
    return options.map((option) => {
      return {
        id: option[idField],
        displayText: option[valueField],
      };
    });
  }

  showConfirmDialog(options: any) {
    const dialogOptions = {
      panelClass: 'dxfw-wdr-confirm-dialog-panel',
      disableClose: true,
      autoFocus: false,
      minWidth: 100,
      data: { ...{ confirmLabel: DEFAULT_BUTTON_LABELS.CONFIRM_LABEL, cancelLabel: DEFAULT_BUTTON_LABELS.CANCEL_LABEL }, ...options },
    };
    return this.dialog.open(ConfirmDialogComponent, dialogOptions);
  }

  getMonthName(monthNumber: string | number): string {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = parseInt(monthNumber.toString(), 10); // Convert monthNumber to an integer
    if (monthIndex < 1 || monthIndex > 12) {
      throw new Error('Invalid month number');
    }
    return monthNames[monthIndex - 1];
  }
}
