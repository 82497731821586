import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class CustomPaginatorIntlService extends MatPaginatorIntl {
  override changes = new Subject<void>();
  override itemsPerPageLabel = 'Items per page:';
  override nextPageLabel = 'Next';
  override previousPageLabel = 'Previous';
  override firstPageLabel = 'First';
  override lastPageLabel = 'Last';

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  };
}
