<div id="dxfw-wdr-build-reports-stepper-container">
  <mat-stepper #stepper [animationDuration]="'0'">
    <mat-step [stepControl]="shareReportFormGroup">
      <app-stepper-header [stepperDetails]="stepperHeader"></app-stepper-header>
      <div class="dxfw-wdr-build-reports-stepper-content dxfw-wdr-mat-select-container" mat-dialog-content>
        <form [formGroup]="shareReportFormGroup">
          <div class="row dxfw-wdr-shared-form-row-width">
            <div class="col-sm-6 col-md-6 col-lg-6 dxfw-wdr-shared-form-field-width">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <label for="tagInput" class="wdr-stepper-label">Search Users</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <app-select-list
                    [selectFormGroup]="shareReportFormGroup"
                    [options]="usersMultiSelectOptions"
                    [selectionConfig]="searchUsersConfig"
                    (selectionChange)="searchUsersValueChangeHandler($event)"></app-select-list>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
              <button class="primary-button action small-button add-btn" (click)="addSearchUsersHandler()">Add</button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="searchedUsersList?.length > 0">
              <ul class="dxfw-wdr-searched-users-list">
                <li *ngFor="let user of searchedUsersList">
                  <span>{{ user.user }}</span>
                  <mat-icon class="remove-icon" (click)="removeSearchedUsersHandler(user)" (keydown)="removeSearchedUsersHandler(user)">close</mat-icon>
                </li>
              </ul>
            </div>
          </div>
          <div class="row m-t-20 dxfw-wdr-shared-form-row-width">
            <div class="col-sm-6 col-md-6 col-lg-6 dxfw-wdr-shared-form-field-width">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 m-b-5">
                  <label for="tagInput" class="wdr-stepper-label d-inline-block">Users Previously Shared With</label>
                  <button
                    class="primary-button action small-button float-right"
                    (click)="prevSharedUsersAddHandler()"
                    [disabled]="prevSharedUsersRef?.selectedOptions.selected.length === 0">
                    Add
                  </button>
                </div>
                <div class="col-md-12">
                  <mat-selection-list #prevSharedUsersRef class="dxfw-wdr-shared-report-users-list" disableRipple>
                    <div class="dxfw-wdr-shared-report-users-select-all">
                      Select All
                      <mat-checkbox
                        class="float-right"
                        [checked]="prevSharedUsersCheckAll"
                        [disabled]="previouslySharedUsersList.length === 0"
                        (change)="selectAllUsersHandler($event.checked, prevSharedUsersRef)"></mat-checkbox>
                    </div>
                    <mat-list-option
                      [title]="user.user"
                      class="mat-mdc-checkbox"
                      *ngFor="let user of previouslySharedUsersList"
                      [value]="user"
                      (selectedChange)="onChangePrevSharedUsersCheckbox()">
                      {{ user.user }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 dxfw-wdr-shared-form-field-width">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 m-b-5">
                  <label for="tagInput" class="wdr-stepper-label d-inline-block">Selected Users</label>
                  <button
                    class="primary-button action small-button float-right"
                    (click)="removeSelectedUsersHandler()"
                    [disabled]="selectedUsersRef?.selectedOptions.selected.length === 0">
                    Remove
                  </button>
                </div>
                <div class="col-md-12">
                  <mat-selection-list #selectedUsersRef class="dxfw-wdr-shared-report-users-list" disableRipple>
                    <div class="dxfw-wdr-shared-report-users-select-all">
                      Select All
                      <mat-checkbox
                        class="float-right"
                        [checked]="selectedUsersCheckAll"
                        [disabled]="selectedUsersList.length === 0"
                        (change)="selectAllUsersHandler($event.checked, selectedUsersRef)"></mat-checkbox>
                    </div>
                    <mat-list-option
                      [title]="user.user"
                      class="mat-mdc-checkbox"
                      *ngFor="let user of selectedUsersList"
                      [value]="user"
                      (selectedChange)="onChangeSelectedUsersCheckbox()">
                      {{ user.user }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="dxfw-wdr-build-reports-stepper-footer">
        <button class="secondary-button action" (click)="closeDialog()">Cancel</button>
        <button class="primary-button action float-right" [disabled]="selectedUsersList.length === 0" (click)="onClickSendBtn()">Send</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
