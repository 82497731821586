import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WDR_CONSTANT } from '../../../../constants/wdr.constant';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
})
export class ReportDetailsComponent {
  @Input() public reportDetailsFormGroup: FormGroup;
  public reportDescriptionMaxLength = 975;
  public fieldRequiredErrorMessage = WDR_CONSTANT.fieldRequiredMessage;
}
