import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, retry, throwError } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { HTTP_ERROR_STATUS_AND_MESSAGE, WDR_CONSTANT } from '../../constants/wdr.constant';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsHttpService {
  constructor(private readonly http: HttpClient, private readonly spinnerService: SpinnerService) {}

  get(path: string, params = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    if (params) {
      params['_ts'] = Date.now();
    }
    return this.http.get(`${path}`, { params: params }).pipe(
      retry(1), // Retry a failed request up to 1 time(s)
      catchError((err, source) => {
        return this.catchErrorHandler(err, source);
      }),
      map((res: Response) => this.successHandler(res))
    );
  }

  getForkJoin(path: string, params = {} as any): Observable<any> {
    if (params) {
      params['_ts'] = Date.now();
    }
    return this.http.get(`${path}`, { params: params }).pipe(
      retry(1), // Retry a failed request up to 1 time(s)
      catchError((err, source) => {
        return this.catchErrorHandler(err, source);
      })
    );
  }

  post(path: string, body = {}, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.post(`${path}`, body, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  postSilent(path: string, body = {}, options = {} as any): Observable<any> {
    return this.http.post(`${path}`, body, options).pipe(map((res) => res));
  }

  put(path: string, body = {}, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.put(`${path}`, body, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  delete(path: string, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.delete(`${path}`, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  makeParallelApiCalls(apiCalls): Observable<any> {
    this.spinnerService.displaySpinner();
    return forkJoin(apiCalls).pipe(map((res: Response) => this.successHandler(res)));
  }

  // Success And Error Handlers
  successHandler(response: any): Observable<any> {
    this.spinnerService.hideSpinner();
    return response;
  }

  catchErrorHandler(error: HttpErrorResponse, _source: any): Observable<any> {
    this.spinnerService.hideSpinner();
    // A client-side or network error occurred. Handle it accordingly.
    if (error.error instanceof ErrorEvent) {
      return throwError(() => new Error(WDR_CONSTANT.errorMessages.unknownError));
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong.
      const errorMessage = HTTP_ERROR_STATUS_AND_MESSAGE[error.status] || WDR_CONSTANT.errorMessages.genericException;
      return throwError(() => new Error(errorMessage));
    }
  }
}
