import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { BuildReportsStepperDialogComponent } from './components/build-reports-stepper-dialog/build-reports-stepper-dialog.component';
import { StepperHeaderComponent } from './components/build-reports-stepper-dialog/stepper-header/stepper-header.component';
import { HttpClientModule } from '@angular/common/http';
import { ReportDetailsComponent } from './components/build-reports-stepper-dialog/report-details/report-details.component';
import { ReportCriteriaComponent } from './components/build-reports-stepper-dialog/report-criteria/report-criteria.component';
import { ReportColumnsComponent } from './components/build-reports-stepper-dialog/report-columns/report-columns.component';
import { ShareReportDialogComponent } from './components/share-report-dialog/share-report-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SelectListComponent } from './components/select-list/select-list.component';
import { SelectStackReportsComponent } from './components/build-reports-stepper-dialog/select-stack-reports/select-stack-reports.component';

@NgModule({
  declarations: [
    AssetUrlPipe,
    SpinnerComponent,
    BuildReportsStepperDialogComponent,
    StepperHeaderComponent,
    ReportDetailsComponent,
    ReportCriteriaComponent,
    ReportColumnsComponent,
    ShareReportDialogComponent,
    ConfirmDialogComponent,
    SelectListComponent,
    SelectStackReportsComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, HttpClientModule],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AssetUrlPipe,
    SpinnerComponent,
    BuildReportsStepperDialogComponent,
    StepperHeaderComponent,
    ReportDetailsComponent,
    ReportCriteriaComponent,
    ReportColumnsComponent,
    ShareReportDialogComponent,
    ConfirmDialogComponent,
    SelectListComponent,
    SelectStackReportsComponent,
  ],
})
export class SharedModule {}
