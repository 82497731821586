import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { assetUrl } from '../../../single-spa/asset-url';

@Pipe({
  name: 'assetUrl',
})
export class AssetUrlPipe implements PipeTransform {
  transform(value: string, folder?: string): any {
    return assetUrl(value, folder, environment.spaHost);
  }
}
