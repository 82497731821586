import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, throwError } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { WDR_CONSTANT } from '../../constants/wdr.constant';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsHttpService {
  constructor(private readonly http: HttpClient, private readonly spinnerService: SpinnerService) {}

  get(path: string, params = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    if (params) {
      params['_ts'] = Date.now();
    }
    return this.http.get(`${path}`, { params: params }).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  post(path: string, body = {}, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.post(`${path}`, body, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  put(path: string, body = {}, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.put(`${path}`, body, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  delete(path: string, options = {} as any): Observable<any> {
    this.spinnerService.displaySpinner();
    return this.http.delete(`${path}`, options).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  makeParallelApiCalls(apiCalls): Observable<any> {
    this.spinnerService.displaySpinner();
    return forkJoin(apiCalls).pipe(
      catchError((err, source) => this.catchErrorHandler(err, source)),
      map((res: Response) => this.successHandler(res))
    );
  }

  // Success And Error Handlers
  successHandler(response: any): Observable<any> {
    this.spinnerService.hideSpinner();
    return response;
  }

  catchErrorHandler(error: any, _source: any): Observable<any> {
    this.spinnerService.hideSpinner();
    if (error.status === 400 || error.status === 500) {
      return throwError(() => WDR_CONSTANT.errorMessages.genericException);
    }
    return throwError(() => ({ ...error, _body: JSON.stringify(error.error) }));
  }
}
