import { Component, OnInit } from '@angular/core';
import { ViewReportService } from '../../services/view-report.service';
import * as wdrConstant from '../../../../constants/wdr.constant';
import { IGetHistoricalTableResponse } from '../../interface/view-report.interface';

@Component({
  selector: 'app-historical-view-report',
  templateUrl: './historical-view-report.component.html',
  styleUrls: ['./historical-view-report.component.scss'],
})
export class HistoricalViewReportComponent implements OnInit {
  spanCount = 1;
  viewReportTableData: IGetHistoricalTableResponse[];
  constant = wdrConstant;
  tableHeader: any;

  constructor(public viewReportService: ViewReportService) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService?.viewReportTableData;
    this.tableHeader = this.keysGetter(this.viewReportTableData?.[0]);
  }

  keysGetter(data: any) {
    return data !== undefined && data !== null ? Object.keys(data) : null;
  }

  rowSpan(data, rowIndex, rowData, dataIndex) {
    let i = 1;
    this.spanCount = 1;
    while (dataIndex === 0 && i !== 0) {
      if (data[rowData] === this.viewReportTableData?.[rowIndex + i]?.[rowData]) {
        this.spanCount++;
        i++;
      } else {
        i = 0;
      }
    }
    return this.spanCount;
  }

  skipSpannedColumn(data, rowIndex, rowData, dataIndex) {
    return !(dataIndex === 0 && data[rowData] === this.viewReportTableData?.[rowIndex - 1]?.[rowData]);
  }
}
