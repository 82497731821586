import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  message: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;

  constructor(public readonly dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

  ngOnInit() {
    this.message = this.modalData['message'];
    this.confirmButtonLabel = this.modalData['confirmLabel'];
    this.cancelButtonLabel = this.modalData['cancelLabel'];
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
