import { ApplicationRef, ComponentRef, Injectable, createComponent } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsDomService {
  constructor(private readonly applicationRef: ApplicationRef) {}

  public loadDynamicComponent(component: any): any {
    const newNode = document.createElement('div');
    newNode.id = 'app-dxfw-wdr-spinner';
    document.body.appendChild(newNode);
    // Create component
    const componentRef = createComponent(component, {
      environmentInjector: this.applicationRef.injector,
      hostElement: newNode,
    });
    // Attach view to the application
    this.applicationRef.attachView(componentRef.hostView);
    return componentRef;
  }

  public removeDynamicComponent(componentRef: ComponentRef<any>): void {
    this.applicationRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
