import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './shared/components/empty-route/empty-route.component';
import { SharedModule } from './shared/shared.module';
import { BuildReportsModule } from './modules/build-reports/build-reports.module';
import { MyReportsModule } from './modules/my-reports/my-reports.module';
import { ViewReportModule } from './modules/view-report/view-report.module';
import { DynamicReportsLayoutComponent } from './components/dynamic-reports-layout/dynamic-reports-layout.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';

@NgModule({
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, SharedModule, BuildReportsModule, MyReportsModule, ViewReportModule],
  declarations: [AppComponent, EmptyRouteComponent, DynamicReportsLayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
