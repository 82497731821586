<div class="dxfw-wdr-my-report-container">
  <ng-container *ngIf="userReportsFiltered && userReportsFiltered.length; else noMyReport">
    <mat-card *ngFor="let userReport of userReportsFiltered">
      <mat-card-content>
        <div class="dxfw-wdr-my-report-cont__title">
          <a (click)="viewReport(userReport)">{{ userReport.report_title }}</a>
        </div>
        <div class="dxfw-wdr-my-report-cont__desc">{{ userReport.report_description }}</div>
      </mat-card-content>
      <mat-card-footer>
        <div class="dxfw-wdr-my-report-cont__actions">
          <button class="primary-button action small-button" title="Share" (click)="reportAction(userReport, myReportAction.SHARE)">
            <mat-icon>share</mat-icon>
          </button>
          <button *ngIf="!isSharedReport" class="primary-button action small-button" title="Edit" (click)="reportAction(userReport, myReportAction.UPDATE)">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="primary-button action small-button" title="Copy" (click)="reportAction(userReport, myReportAction.COPY)">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button *ngIf="!isSharedReport" class="primary-button action small-button" title="Delete" (click)="reportAction(userReport, myReportAction.DELETE)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </ng-container>
  <ng-template #noMyReport>
    <div class="no-record full-width text-center">{{ noReportMessage }}</div>
  </ng-template>
  <div class="dxfw-wdr-my-report-paginator-container" *ngIf="userReports.length">
    <mat-paginator
      #paginator
      [length]="userReports.length"
      [pageSize]="matPaginatorConfig.pageSize"
      [pageSizeOptions]="matPaginatorConfig.pageSizeOptions"
      [showFirstLastButtons]="matPaginatorConfig.showFirstLastButtons"
      [selectConfig]="matPaginatorConfig.selectConfig"
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
