import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DYNAMIC_REPORTS_API_URLS } from '../../../constants/wdr.constant';
import { DynamicReportsHttpService } from '../../../shared/services/dynamic-reports-http.service';
import { ICopyReportRequest, IDeleteReportRequest, IMyReports } from '../interface/my-reports.interface';
import { ICommonResponse } from '../../../shared/interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class MyReportsService {
  public isReportUpdatedObs = new Subject<boolean>();

  constructor(private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  getMyReports(api: string): Observable<IMyReports[]> {
    return this.dynamicReportsHttpService.get(api);
  }

  copyReport(requestParam: ICopyReportRequest): Observable<ICommonResponse> {
    return this.dynamicReportsHttpService.post(DYNAMIC_REPORTS_API_URLS().GENERATE_REPORT, requestParam);
  }

  deleteReport(requestParam: IDeleteReportRequest): Observable<ICommonResponse> {
    return this.dynamicReportsHttpService.post(DYNAMIC_REPORTS_API_URLS().DELETE_REPORT, requestParam);
  }
}
