import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildReportsDashboardComponent } from './components/build-reports-dashboard/build-reports-dashboard.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [BuildReportsDashboardComponent],
  exports: [BuildReportsDashboardComponent],
})
export class BuildReportsModule {}
