import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { IReportColumnRequestParam } from '../../../../shared/interface/build-reports-stepper.interface';
import { DynamicReportsDialogService } from '../../../../shared/services/dynamic-reports-dialog.service';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { ISelectionConfig } from '../../../../shared/interface/common.interface';
import { MyReportsService } from '../../../../modules/my-reports/services/my-reports.service';
import { IMyReports } from '../../../../modules/my-reports/interface/my-reports.interface';
import { DROPDOWN_CONFIG } from '../../../../constants/dropdown-config.constant';
import { DYNAMIC_REPORTS_API_URLS, WDR_CONSTANT } from '../../../../constants/wdr.constant';

@Component({
  selector: 'app-select-stack-reports',
  templateUrl: './select-stack-reports.component.html',
  styleUrls: ['./select-stack-reports.component.scss'],
})
export class SelectStackReportsComponent implements OnInit {
  @Input() selectStackReportsFormGroup: FormGroup;
  @Input() reportCriteriaFormData: IReportColumnRequestParam;
  @Input() selectedStackReportsTableDataSource: any[] = [];
  @ViewChild('selectedFieldsTable', { static: true }) selectedFieldsTable: MatTable<any>;

  reportNameOptions = [];
  reportNameDropdownConfig: ISelectionConfig = DROPDOWN_CONFIG.SELECT_REPORT_CONTROL.REPORT_NAME;
  tempSelectedColumn = {};
  allowedReportFieldLimit = WDR_CONSTANT.allowedReportFieldLimit;

  //Selected Field Table Configuration
  selectedFieldsTableColumn: string[] = ['report_name', 'action'];
  emptyData = new MatTableDataSource([{ empty: 'row' }]);
  dragDisabled = true;

  constructor(
    private readonly myReportsService: MyReportsService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    private readonly dynamicReportsDialogService: DynamicReportsDialogService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {
    this.getAllReportNames();
  }

  getAllReportNames(): void {
    const apiURL = DYNAMIC_REPORTS_API_URLS().GET_MY_REPORTS;
    this.myReportsService.getMyReports(apiURL).subscribe({
      next: (responseData: IMyReports[]) => {
        const allReports = JSON.parse(JSON.stringify(responseData)) || [];
        this.reportNameOptions = allReports
          .filter((report: IMyReports) => report.template_id !== 4)
          .map((report: IMyReports) => {
            return { id: report.id, report_title: report.report_title };
          });
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }

  mapDataSource(dataSource: any) {
    dataSource['selectedOptions'].forEach((selectedOption: any) => {
      this.tempSelectedColumn[dataSource.controlName] = selectedOption;
    });
  }

  validateAddItem() {
    if (this.selectStackReportsFormGroup.valid) {
      if (!this.checkForDuplicateColumn()) {
        const rowData = {
          sub_report_id: this.tempSelectedColumn['reportName']['id'],
          report_name: this.tempSelectedColumn['reportName']['report_title'],
        };
        this.selectedStackReportsTableDataSource.push(rowData);
        this.selectedFieldsTable.renderRows();
        this.resetItem();
        this.dynamicReportsUtilityService.showSuccessMessage(WDR_CONSTANT.reportAddedSuccessMessage);
      } else {
        this.dynamicReportsUtilityService.showErrorMessage(WDR_CONSTANT.reportAlreadyExistMessage);
      }
    }
  }

  checkForDuplicateColumn(): boolean {
    return this.selectedStackReportsTableDataSource.some((tableData) => tableData['sub_report_id'] === this.tempSelectedColumn['reportName']['id']);
  }

  resetItem() {
    this.selectStackReportsFormGroup.reset();
    this.tempSelectedColumn = {};
  }

  drop(event: any) {
    this.dragDisabled = true;
    const previousIndex = this.selectedStackReportsTableDataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.selectedStackReportsTableDataSource, previousIndex, event.currentIndex);
    this.selectedFieldsTable.renderRows();
  }

  deleteReportColumn(rowIndex: number) {
    this.selectedStackReportsTableDataSource.splice(rowIndex, 1);
    this.selectedFieldsTable.renderRows();
  }
}
