<table aria-describedby="dxfw-report-content-table" id="dxfw-wdr-view-report-table">
  <thead>
    <tr>
      <th *ngFor="let header of tableHeader; index as headerIndex" class="dxfw-wdr-view-report-header-cell">
        {{ header }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of viewReportTableData; index as rowIndex">
      <ng-container *ngFor="let rowData of keysGetter(data); index as dataIndex">
        <td
          *ngIf="skipSpannedColumn(data, rowIndex, rowData, dataIndex)"
          [attr.rowspan]="rowSpan(data, rowIndex, rowData, dataIndex)"
          class="dxfw-wdr-view-report-body-cell">
          {{ data[rowData] }}
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
