<div class="dxfw-wdr-container">
  <div class="dxfw-wdr-header">
    <span class="dxfw-wdr-header__title pr-3">Dynamic Reports</span>
  </div>
  <div class="dxfw-wdr-body-wrapper">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false">
      <a
        mat-tab-link
        *ngFor="let menuTab of menuTabCollections"
        #menuLinkActive="routerLinkActive"
        [active]="menuLinkActive.isActive"
        [routerLink]="menuTab.path"
        routerLinkActive>
        {{ menuTab.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
