<form [formGroup]="reportDetailsFormGroup">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Report Type</label>
    </div>
    <div class="col-md-12">
      <mat-form-field [floatLabel]="'auto'" class="dd-mat-mdc-input-form-field full-width">
        <input matInput placeholder="Enter Report Type" formControlName="reportType" required />
        <mat-error *ngIf="reportDetailsFormGroup.get('reportType').hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Report Title</label>
    </div>
    <div class="col-md-12">
      <mat-form-field [floatLabel]="'auto'" class="dd-mat-mdc-input-form-field full-width">
        <input matInput placeholder="Enter Report Title" formControlName="reportTitle" required />
        <mat-error *ngIf="reportDetailsFormGroup.get('reportTitle').hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Report Description</label>
    </div>
    <div class="col-md-12">
      <mat-form-field [floatLabel]="'auto'" class="dd-mat-mdc-input-form-field full-width">
        <textarea
          matInput
          [maxlength]="reportDescriptionMaxLength"
          placeholder="Enter Report Description"
          formControlName="reportDescription"
          required></textarea>
        <mat-hint>Characters remaining {{ reportDescriptionMaxLength - reportDetailsFormGroup.get('reportDescription')?.value?.length }}</mat-hint>
        <mat-error *ngIf="reportDetailsFormGroup.get('reportDescription').hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
