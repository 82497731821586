import { Injectable } from '@angular/core';
import { DynamicReportsHttpService } from './dynamic-reports-http.service';
import { DYNAMIC_REPORTS_API_URLS } from '../../constants/wdr.constant';
import { IActivityLogParams } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsUserActivityLogsService {
  constructor(private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  getBrowserName(): string {
    const browserInfo = navigator.userAgent;
    const browsers = [
      { name: 'Opera', identifier: ['Opera', 'Opr'] },
      { name: 'Edge', identifier: ['Edg'] },
      { name: 'Chrome', identifier: ['Chrome'] },
      { name: 'Safari', identifier: ['Safari'] },
      { name: 'Firefox', identifier: ['Firefox'] },
    ];
    for (const browser of browsers) {
      if (browser.identifier.some((id) => browserInfo.includes(id))) {
        return browser.name;
      }
    }
    return 'unknown';
  }

  addActivityLog(params: IActivityLogParams) {
    return this.dynamicReportsHttpService.postSilent(DYNAMIC_REPORTS_API_URLS().ADD_ACTIVITY_LOG, params);
  }
}
