import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  IGenerateReportRequest,
  IReportColumnRequestParam,
  IReportColumnResponse,
  IReportCriteriaResponse,
} from '../interface/build-reports-stepper.interface';
import { DynamicReportsHttpService } from './dynamic-reports-http.service';
import { DYNAMIC_REPORTS_API_URLS } from '../../constants/wdr.constant';
import { IGetUserDetails } from '../interface/share-report-dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class DynamicReportsDialogService {
  constructor(private readonly dialog: MatDialog, private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  openDialog(component: any, configData?: any) {
    return this.dialog.open(component, configData);
  }

  getReportCriteria(params): Observable<IReportCriteriaResponse> {
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_REPORT_CRITERIA, params);
  }

  getReportColumn(params: IReportColumnRequestParam): Observable<IReportColumnResponse> {
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_REPORT_COLUMN, params);
  }

  generateReport(params: IGenerateReportRequest) {
    return this.dynamicReportsHttpService.post(DYNAMIC_REPORTS_API_URLS().GENERATE_REPORT, params);
  }

  getUserDetailsToShareReport(params): Observable<IGetUserDetails> {
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_USER_DETAILS, params);
  }

  saveShareReport(params) {
    return this.dynamicReportsHttpService.post(DYNAMIC_REPORTS_API_URLS().SAVE_SHARE_REPORT, params);
  }

  getEditReportCriteriaApiCalls(apiCalls) {
    return this.dynamicReportsHttpService.makeParallelApiCalls(apiCalls);
  }
}
