import { IConfig } from '../../app/shared/interface/common.interface';

export const IEnvironmentConfig: { [index: string]: IConfig } = {
  localhost: {
    production: false,
    API_END_POINT: 'https://dxfw-api.dev.dealer.toyota.com',
    spaHost: 'localhost:4200',
    name: 'LOCAL',
    forgerockUrl: 'https://ep.fram.qa.idm.toyota.com/openam/idpssoinit?metaAlias=/dealerdaily/idp49&spEntityID=urn:amazon:webservices2',
    disableAPICall: false,
    enableCustomReport: true,
  },
  'dev.dealer.toyota.com': {
    production: true,
    API_END_POINT: 'https://dxfw-api.dev.dealer.toyota.com',
    spaHost: 'https://dxfw-dynamicreports.dev.dealer.toyota.com',
    name: 'DEV',
    forgerockUrl: 'https://ep.fram.qa.idm.toyota.com/openam/idpssoinit?metaAlias=/dealerdaily/idp49&spEntityID=urn:amazon:webservices2',
    disableAPICall: false,
    enableCustomReport: true,
  },
  'test.dealer.toyota.com': {
    production: true,
    API_END_POINT: 'https://dxfw-api.test.dealer.toyota.com',
    spaHost: 'https://dxfw-dynamicreports.test.dealer.toyota.com',
    name: 'TEST',
    forgerockUrl: 'https://ep.fram.qa.idm.toyota.com/openam/idpssoinit?metaAlias=/dealerdaily/idp55&spEntityID=urn:amazon:webservicesworkoutest',
    disableAPICall: false,
    enableCustomReport: false,
  },
  'qa.dealer.toyota.com': {
    production: true,
    API_END_POINT: 'https://dxfw-api.qa.dealer.toyota.com',
    spaHost: 'https://dxfw-dynamicreports.qa.dealer.toyota.com',
    name: 'QA',
    forgerockUrl: 'https://ep.fram.qa.idm.toyota.com/openam/idpssoinit?metaAlias=/dealerdaily/idp55&spEntityID=urn:amazon:webservicesworkoutest',
    disableAPICall: false,
    enableCustomReport: false,
  },
  'dealer.toyota.com': {
    production: true,
    API_END_POINT: 'https://dxfw-api.dealer.toyota.com',
    spaHost: 'https://dxfw-dynamicreports.dealer.toyota.com',
    name: 'PROD',
    forgerockUrl: 'https://ep.fram.idm.toyota.com/openam/idpssoinit?metaAlias=/dealerdaily/idp39&spEntityID=urn:amazon:webservicesworkoutprod',
    disableAPICall: false,
    enableCustomReport: false,
  },
};
