import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './shared/components/empty-route/empty-route.component';
import { BuildReportsDashboardComponent } from './modules/build-reports/components/build-reports-dashboard/build-reports-dashboard.component';
import { MyReportsDashboardComponent } from './modules/my-reports/components/my-reports-dashboard/my-reports-dashboard.component';
import { ViewReportContainerComponent } from './modules/view-report/components/view-report-container/view-report-container.component';
import { DynamicReportsLayoutComponent } from './components/dynamic-reports-layout/dynamic-reports-layout.component';

const routes: Routes = [
  {
    path: 'workout-dynamic-reports',
    children: [
      {
        path: 'home',
        component: DynamicReportsLayoutComponent,
        children: [{ path: '', component: BuildReportsDashboardComponent }],
      },
      {
        path: 'my-reports',
        data: { isSharedReport: false },
        component: DynamicReportsLayoutComponent,
        children: [{ path: '', component: MyReportsDashboardComponent }],
      },
      {
        path: 'shared-reports',
        data: { isSharedReport: true },
        component: DynamicReportsLayoutComponent,
        children: [{ path: '', component: MyReportsDashboardComponent }],
      },
      {
        path: 'view-report',
        component: ViewReportContainerComponent,
      },
    ],
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
