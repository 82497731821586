import { Component, OnInit } from '@angular/core';
import { ViewReportService } from '../../services/view-report.service';
import { IGetReportTableResponse } from '../../interface/view-report.interface';
import * as wdrConstant from '../../../../constants/wdr.constant';

@Component({
  selector: 'app-hierarchical-view-report',
  templateUrl: './hierarchical-view-report.component.html',
  styleUrls: ['./hierarchical-view-report.component.scss']
})
export class HierarchicalViewReportComponent implements OnInit {
  tableHeader = [];
  viewReportTableData: IGetReportTableResponse[];
  constant = wdrConstant;

  constructor(
    public viewReportService: ViewReportService,
  ) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService.viewReportTableData;
    this.setReportTableConfig();
  }

  setReportTableConfig() {
    this.viewReportTableData?.forEach((tConfig) => {
      if (!this.tableHeader.length) {
        tConfig.rows.forEach((rowData) => {
          this.tableHeader.push({columnName:rowData?.column_name, format:rowData?.format});
        });
      }
    });
  }
}
