import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DYNAMIC_REPORTS_API_URLS } from '../../../constants/wdr.constant';
import { DynamicReportsHttpService } from '../../../shared/services/dynamic-reports-http.service';
import { IReportTemplate } from '../interface/build-report.interface';

@Injectable({
  providedIn: 'root',
})
export class BuildReportsService {
  templates: IReportTemplate[];
  constructor(private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  set reportTemplates(templates: IReportTemplate[]) {
    this.templates = templates;
  }

  get reportTemplates() {
    return this.templates;
  }

  getReportTemplates(): Observable<IReportTemplate[]> {
    return this.dynamicReportsHttpService.get(DYNAMIC_REPORTS_API_URLS().GET_REPORT_TEMPLATES);
  }
}
