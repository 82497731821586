import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyReportsDashboardComponent } from './components/my-reports-dashboard/my-reports-dashboard.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [MyReportsDashboardComponent],
  exports: [MyReportsDashboardComponent],
})
export class MyReportsModule {}
