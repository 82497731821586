<div class="dxfw-wdr-confirm-dialog-container">
  <div class="confirm-title">Confirmation</div>
  <div class="confirm-content">
    <mat-icon>warning</mat-icon>
    <span class="confirm-message" [innerHTML]="message"></span>
  </div>
  <div class="confirm-action">
    <button (click)="onCancel()" class="secondary-button">{{ cancelButtonLabel }}</button>
    <button (click)="onConfirm()" class="primary-button">{{ confirmButtonLabel }}</button>
  </div>
</div>
