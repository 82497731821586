import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  IReportCriteriaCompany,
  IReportCriteriaComparatorCriteriaHierarchyLevel,
  IReportCriteriaDataSource,
  IReportCriteriaHierarchyLevel,
  IReportCriteriaHierarchyType,
  IReportCriteriaResponse,
  IReportCriteriaTimePeriod,
} from '../../../../shared/interface/build-reports-stepper.interface';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { REPORT_CRITERIA } from '../../../../constants/build-reports-stepper.constant';
import { REPORT_ACTION, TEMPLATE_ID, WDR_CONSTANT } from '../../../../constants/wdr.constant';
import { DynamicReportsDialogService } from '../../../../shared/services/dynamic-reports-dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { tooltipTable } from '../../../../shared/mocks/tooltip-table';

const CONTROL_NAMES = {
  TIME_PERIOD: 'timePeriod',
  COMPARATOR_CRITERIA_HIERARCHY_LEVEL: 'comparatorCriteriaHierarchyLevel',
};

@Component({
  selector: 'app-report-criteria',
  templateUrl: './report-criteria.component.html',
  styleUrls: ['./report-criteria.component.scss'],
})
export class ReportCriteriaComponent implements OnInit {
  @Input() public reportCriteriaResponseData: IReportCriteriaResponse;
  @Input() public reportCriteriaFormGroup: FormGroup;

  public formControlNames: string[];
  public formControlOptions: string[];
  public relationshipKeysConfig: { [key: string]: any };
  public hierarchicalControlAndReqParamKeys: { [key: string]: any };
  public fieldRequiredErrorMessage = WDR_CONSTANT.fieldRequiredMessage;
  public templateId = TEMPLATE_ID;

  public dataSourceOptions: IReportCriteriaDataSource[] = [];
  public hierarchyTypeOptions: IReportCriteriaHierarchyType[] = [];
  public hierarchyLevelOptions: IReportCriteriaHierarchyLevel[] = [];
  public companyOptions: IReportCriteriaCompany[] = [];
  public timePeriodOptions: IReportCriteriaTimePeriod[] = [];
  public comparatorCriteriaHierarchyLevelOptions: IReportCriteriaComparatorCriteriaHierarchyLevel[] = [];
  showTooltip = false;
  tooltipTopPosition;
  tooltipLeftPosition;
  tooltipData = tooltipTable;
  isTooltipTable = true;

  constructor(
    public readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    public readonly dynamicReportsDialogService: DynamicReportsDialogService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    this.setFormControlAndDropdownConfigByTemplateId(this.getReportTemplateId());
  }

  setFormControlAndDropdownConfigByTemplateId(templateId: number): void {
    this.formControlNames = [...REPORT_CRITERIA.FORM_CONTROL_NAMES];
    this.formControlOptions = [...REPORT_CRITERIA.FORM_CONTROL_OPTIONS];
    this.relationshipKeysConfig = this.dynamicReportsUtilityService.deepCopy(REPORT_CRITERIA.RELATIONSHIP_KEYS_CONFIG);
    this.hierarchicalControlAndReqParamKeys = { ...REPORT_CRITERIA.HIERARCHICAL_REQ_PARAMS };
    if (templateId === this.templateId.EXTERNAL_COMPARISON) {
      this.formControlNames.push(CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL);
      this.formControlOptions.push(CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL + 'Options');
      this.relationshipKeysConfig[CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL] = [];
    }
  }

  ngOnInit(): void {
    this.loadReportCriteriaDetailByAction();
  }

  ngOnChanges(inputDataChanges: SimpleChanges) {
    if (inputDataChanges['reportCriteriaResponseData']?.currentValue) {
      this.reportCriteriaResponseData = inputDataChanges['reportCriteriaResponseData'].currentValue;
      this.setDataSourceOptions(); // Set Data Source Options when component is loaded
    }
  }

  isCreateReport(): boolean {
    return this.modalData.reportAction === REPORT_ACTION.CREATE;
  }

  isUpdateReport(): boolean {
    return this.modalData.reportAction === REPORT_ACTION.UPDATE;
  }

  getReportTemplateId() {
    return this.modalData.reportType.id;
  }

  async getReportCriteriaDetails(reqParams): Promise<IReportCriteriaResponse> {
    try {
      const responseData: IReportCriteriaResponse = await firstValueFrom(this.dynamicReportsDialogService.getReportCriteria(reqParams));
      return responseData; // Return the data directly
    } catch (error) {
      this.dynamicReportsUtilityService.showErrorMessage(error);
      throw error; // Rethrow the error if you need to handle it outside
    }
  }

  async loadReportCriteriaDetailByAction() {
    try {
      if (!this.reportCriteriaResponseData && this.isCreateReport()) {
        const reqParams = { template_id: this.getReportTemplateId() };
        this.reportCriteriaResponseData = await this.getReportCriteriaDetails(reqParams);
        this.setDataSourceOptions();
      } else if (!this.reportCriteriaResponseData && this.isUpdateReport() && this.modalData.isFirstChange) {
        const reqParamsList = this.prepareAllDropdownHttpRequestParams();
        const dropdownApiCalls = reqParamsList.map((params) => this.dynamicReportsDialogService.getReportCriteria(params));
        this.getAllDropdownOptionsOfReportCriteria(dropdownApiCalls);
      }
    } catch (error) {
      this.dynamicReportsUtilityService.showErrorMessage(error);
    }
  }

  prepareAllDropdownHttpRequestParams() {
    const reportDetails = this.dynamicReportsUtilityService.deepCopy(this.modalData.reportDetails);
    const reqParamObjByDropdownOrder = [];
    const reqParamKeyValueMappings = [
      { template_id: reportDetails.template_id },
      { datasource_id: reportDetails.datasource_id },
      { hierarchy_id: reportDetails.hierarchy_id },
      { level_id: reportDetails.level_id },
      { company_id: reportDetails.company_id },
    ]; // Default is Hierarchy Report
    if (reportDetails.template_id === this.templateId.EXTERNAL_COMPARISON) {
      reqParamKeyValueMappings.push({ time_period_id: reportDetails.time_period_id } as any);
    }
    let prevObj = {};
    reqParamKeyValueMappings.forEach((currObj) => {
      const newObj = { ...prevObj, ...currObj };
      reqParamObjByDropdownOrder.push(newObj);
      prevObj = newObj;
    });
    return reqParamObjByDropdownOrder;
  }

  /** This method is used to retrieve all dropdown options during Edit Report to prepopulate the form fields */
  getAllDropdownOptionsOfReportCriteria(allDropdownApiCalls) {
    this.dynamicReportsDialogService.getEditReportCriteriaApiCalls(allDropdownApiCalls).subscribe({
      next: (responses: IReportCriteriaResponse[]) => {
        this.getAllDropdownOptionsAPIResponseHandler(responses);
      },
      error: (err) => {
        this.dynamicReportsUtilityService.showErrorMessage(`Error during API calls: ${err?.message}`);
      },
    });
  }

  initializeReportCriteriaResponseData() {
    const formControlNames = this.getFormControlsByTemplateId();
    const obj = {} as IReportCriteriaResponse;
    formControlNames.forEach((formControlName) => (obj[formControlName] = []));
    this.reportCriteriaResponseData = obj;
  }

  getAllDropdownOptionsAPIResponseHandler(responses: IReportCriteriaResponse[]) {
    if (!this.reportCriteriaResponseData) {
      this.initializeReportCriteriaResponseData();
    }
    // Note: reportCriteriaResponseData key name must be match with the API response key name
    responses.forEach((response) => {
      Object.keys(response).forEach((key) => (this.reportCriteriaResponseData[key] = response[key]));
    });
    this.setReportCriteriaDropdownOptions(); // After processing responses, prepare options and set form values
    setTimeout(() => this.setHierarchyReportFormValues());
  }

  setReportCriteriaDropdownOptions() {
    for (const [index, controlName] of this.formControlNames.entries()) {
      const controlOptionName = this.formControlOptions[index];
      this[controlOptionName] = this.reportCriteriaResponseData[controlName];
    }
  }

  // Note: This method has been used more than once in the component
  getFormControlsByTemplateId() {
    const formControlNames = ['dataSource', 'hierarchyType', 'hierarchyLevel', 'company', 'timePeriod'];
    if (this.getReportTemplateId() === this.templateId.EXTERNAL_COMPARISON) {
      formControlNames.push(CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL);
    }
    return formControlNames;
  }

  enableFormControls(formControlNames: string[]) {
    formControlNames.forEach((controlName) => this.reportCriteriaFormGroup.get(controlName)?.enable());
  }

  setFormControlValue(controlName: string, value: any) {
    this.reportCriteriaFormGroup.get(controlName)?.setValue(value);
  }

  setHierarchyReportFormValues() {
    const formControlNames = this.getFormControlsByTemplateId();
    const reportDetails = this.dynamicReportsUtilityService.deepCopy(this.modalData.reportDetails);
    const formControlMappings = {
      dataSource: '' + reportDetails.datasource_id,
      hierarchyType: '' + reportDetails.hierarchy_id,
      hierarchyLevel: '' + reportDetails.level_id,
      company: '' + reportDetails.company_id,
      timePeriod: '' + reportDetails.time_period_id,
    }; // FormControlMappings are used for dropdown controls and enable them by iterating. The default is Hierarchy Report.
    if ([this.templateId.HIERARCHICAL].includes(reportDetails.template_id)) {
      formControlMappings['showAllDealers'] = reportDetails.hierarchial_show_all_dealer;
      formControlMappings['showTerminatedDealers'] = reportDetails.hierarchial_show_term_dealer;
    }
    if (reportDetails.template_id === this.templateId.EXTERNAL_COMPARISON) {
      formControlMappings[CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL] = '' + reportDetails.comparison_type_rcid;
    }
    if (reportDetails.template_id === this.templateId.HISTORICAL) {
      formControlMappings['showTotalColumn'] = reportDetails.historical_show_total_column;
    }
    Object.keys(formControlMappings).forEach((controlName) => this.setFormControlValue(controlName, formControlMappings[controlName]));
    this.enableFormControls(formControlNames);
  }

  setDataSourceOptions(): void {
    if (this.reportCriteriaResponseData?.dataSource?.length > 0) {
      this.dataSourceOptions = this.dynamicReportsUtilityService.deepCopy(this.reportCriteriaResponseData.dataSource);
    }
  }

  onSelectionChange(fieldName: string) {
    const formControlNameIndex = this.formControlNames.indexOf(fieldName);
    if (formControlNameIndex === -1) {
      return;
    }
    const formControlName = this.formControlNames[formControlNameIndex];
    const formControlValue = this.reportCriteriaFormGroup.get(formControlName).value;
    this.clearNextSiblings(formControlNameIndex);
    if (formControlValue) {
      this.getFormControlOptionsByTemplateId(fieldName, formControlNameIndex);
    }
  }

  getFormControlOptionsByTemplateId(fieldName: string, formControlNameIndex: number) {
    if (this.getReportTemplateId() === this.templateId.EXTERNAL_COMPARISON) {
      fieldName !== CONTROL_NAMES.COMPARATOR_CRITERIA_HIERARCHY_LEVEL && this.getFormControlOptions(formControlNameIndex);
    } else {
      fieldName !== CONTROL_NAMES.TIME_PERIOD && this.getFormControlOptions(formControlNameIndex);
    }
  }

  async getFormControlOptions(formControlNameIndex: number) {
    const reqParams = this.getParamsFromFormControl();
    const responseData = await this.getReportCriteriaDetails(reqParams);
    if (responseData) {
      const nextFormControlName = this.formControlNames[formControlNameIndex + 1];
      this.reportCriteriaResponseData[nextFormControlName] = responseData[nextFormControlName];
      this.loadNextSiblingOptions(formControlNameIndex);
    }
  }

  getParamsFromFormControl() {
    const hierarchicalReqParams = { template_id: this.getReportTemplateId() };
    for (const [controlName, value] of Object.entries(this.hierarchicalControlAndReqParamKeys)) {
      if (this.reportCriteriaFormGroup.get(controlName)?.value) {
        hierarchicalReqParams[value] = this.reportCriteriaFormGroup.get(controlName)?.value;
      }
    }
    return hierarchicalReqParams;
  }

  clearNextSiblings(formControlNameIndex: number): void {
    const sliceFromIndex = formControlNameIndex + 1;
    const slicedFormControlNames = this.formControlNames.slice(sliceFromIndex);
    const slicedFormControlOptions = this.formControlOptions.slice(sliceFromIndex);

    for (const [_i, formControlName] of slicedFormControlNames.entries()) {
      this.reportCriteriaFormGroup.get(formControlName)?.setValue('');
      this.reportCriteriaFormGroup.get(formControlName)?.disable();
    }
    for (const [_i, formControlOption] of slicedFormControlOptions.entries()) {
      this[formControlOption] = [];
    }
  }

  loadNextSiblingOptions(formControlNameIndex: number): void {
    if (this.formControlNames.length - 1 === formControlNameIndex) {
      return;
    }
    const nextFormControlName = this.formControlNames[formControlNameIndex + 1];
    const nextFormControlOption = this.formControlOptions[formControlNameIndex + 1];
    const nextFormControlOptionCopy = this.dynamicReportsUtilityService.deepCopy(this.reportCriteriaResponseData[nextFormControlName]);

    if (nextFormControlOptionCopy?.length > 0) {
      if (this.relationshipKeysConfig[nextFormControlName].length === 0) {
        this[nextFormControlOption] = nextFormControlOptionCopy;
      } else {
        this[nextFormControlOption] = nextFormControlOptionCopy.filter((optionObj) => {
          return this.relationshipKeysConfig[nextFormControlName].every((relKey) => {
            return +optionObj[relKey.keyName] === +this.reportCriteriaFormGroup.get(relKey.controlName).value;
          });
        });
      }
    } else {
      this[nextFormControlOption] = [];
    }
    this.reportCriteriaFormGroup.get(nextFormControlName)?.enable();
  }

  displayTooltip(e: MouseEvent) {
    this.showTooltip = true;
    this.tooltipTopPosition = e.clientY;
    this.tooltipLeftPosition = e.clientX;
  }
}
