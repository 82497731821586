import { Component } from '@angular/core';
import { MENU_TAB_CONFIG } from '../../constants/wdr.constant';

@Component({
  selector: 'app-dynamic-reports-layout',
  templateUrl: './dynamic-reports-layout.component.html',
  styleUrls: ['./dynamic-reports-layout.component.scss'],
})
export class DynamicReportsLayoutComponent {
  menuTabCollections = MENU_TAB_CONFIG;
}
