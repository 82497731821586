 <table aria-describedby="dxfw-report-content-table" id="dxfw-wdr-view-report-table">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader; index as headerIndex" class="dxfw-wdr-view-report-header-cell"
          [ngClass]="(header?.format) ? 'align-right' : 'align-left'">
          {{ header?.columnName }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of viewReportTableData" [ngStyle]="{'background-color': data?.level_id ? constant.VIEW_REPORT_COLOR_CODE[data?.level_id] : ''}">
        <td *ngFor="let rowData of data.rows; index as dataIndex" class="dxfw-wdr-view-report-body-cell" [ngClass]="(rowData?.format) ? 'align-right' : 'align-left'">
          {{ rowData?.format !== 'currency' ? rowData?.value : (rowData?.value | currency) }}
        </td>
      </tr>
    </tbody>
  </table>
